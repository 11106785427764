import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { IconNavigation, IconWrapper } from '@rushplay/icons'
import { css } from '@styled-system/css'

import { Amount } from '../../components/common/amount'
import { Badge } from '../common/badge'
import { CasinoNavigation } from '../casino-navigation'
import { IconCircle } from '../../components/common/icon-circle'
import { Logo } from '../logo'
import { NotificationBell } from '../../components/icons'
import { NotificationsScreen } from '../notifications-screen'

export const Balance = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  border-radius: 50px;
  cursor: pointer;
  white-space: nowrap;
  lain-height: 20px;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px rgba(204, 68, 0, 0.5);
  ${css({
    color: 'simpleorange',
    fontSize: 1,
    fontWeight: 700,
    backgroundColor: 'white',
    pt: '2px',
    px: 1,
  })}
  &:hover {
    border: 1px solid #ff8000;
    cursor: pointer;
  }
  &:active {
    box-shadow: inset 0px 3px 4px rgba(204, 68, 0, 0.5);
  }
`

const Wrapper = styled('header', {
  shouldForwardProp: Common.noneOf(['isLandingPage']),
})`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  z-index: 120;
  flex-shrink: 0;
  flex-grow: 0;

  ${props => props.isLandingPage && 'position: fixed;'}

  background-color: ${props =>
    props.isLandingPage ? 'transparent' : t.color('simpleorange')};

  ${t.mediaQuery.sm`
    flex-wrap: nowrap;
  `};
`

const InnerWrapper = styled.div`
  padding-top: 12px;
  padding-left: ${t.spacing(1)};
  padding-right: ${t.spacing(1)};
  padding-bottom: ${props => (props.isCasinoPage ? null : t.spacing(0))};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;

  ${t.mediaQuery.sm`
    flex-wrap: nowrap;
  `};
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  ${t.mediaQuery.sm`
    padding-right: ${t.spacing(2)};
  `};
`
const StyledIconCircle = styled(IconCircle)`
  color: ${props => t.color(props.color)};
  box-shadow: 0px 2px 4px rgba(204, 68, 0, 0.5);
  &:hover {
    border: 1px solid ${t.color('simpleorange')};
    cursor: pointer;
  }
  &:active {
    box-shadow: inset 0px 3px 4px rgba(204, 68, 0, 0.5);
  }
`

export function Topbar(props) {
  if (props.countryCode === 'DE' && process.env.PLAYBOOM_LANDING_PAGE === '1') {
    return null
  }

  const isLandingPage = Boolean(
    ReactRouterDom.useRouteMatch({ path: '/', exact: true })
  )
  const isStarsPlayPromoPage = Boolean(
    ReactRouterDom.useRouteMatch({ path: '/starsplaypromo', exact: true })
  )
  const isCasinoPage = Boolean(ReactRouterDom.useRouteMatch('/casino'))
  return (
    <Wrapper isLandingPage={isLandingPage || isStarsPlayPromoPage}>
      {!props.isEmbedded && (
        <Common.Box position="relative" width="100%" margin="auto">
          <NotificationsScreen />
          <InnerWrapper isCasinoPage={isCasinoPage}>
            <LogoWrapper>
              {!isStarsPlayPromoPage && (
                <ReactRouterDom.Route path="/:page([a-zA-Z\-]+)">
                  <Logo minifyLogo />
                </ReactRouterDom.Route>
              )}
            </LogoWrapper>
            <ReactRouterDom.Route
              path="/casino/:tag?"
              component={CasinoNavigation}
            />
            <Common.Flex>
              {props.authenticated && (
                <Common.Box
                  position="relative"
                  marginRight={[1, null, null, 2]}
                >
                  <Badge
                    notification={props.hasNewNotifications}
                    backgroundColor="#2db200"
                    border={{ width: '2px', style: 'solid', color: 'white' }}
                    fontSize="9px"
                  >
                    <StyledIconCircle
                      color="#61696b"
                      size="36"
                      onClick={() => props.onToggleNotifications()}
                    >
                      <IconWrapper size="20px" color="simpleorange">
                        <NotificationBell />
                      </IconWrapper>
                    </StyledIconCircle>
                  </Badge>
                </Common.Box>
              )}
              {props.authenticated && (
                <Common.Space marginRight={1}>
                  <Balance onClick={props.onShowDeposit}>
                    <Amount decimals="show">{props.balanceCents}</Amount>
                  </Balance>
                </Common.Space>
              )}
              <Common.Box>
                <Badge
                  notification={props.hasNewNotifications}
                  backgroundColor="#2db200"
                  border={{ width: '2px', style: 'solid', color: 'white' }}
                  fontSize="9px"
                >
                  <StyledIconCircle
                    color="simpleorange"
                    hoverColor="simpleorange"
                    size="36"
                    onClick={props.onShowSidebar}
                  >
                    <IconWrapper size="14px">
                      <IconNavigation />
                    </IconWrapper>
                  </StyledIconCircle>
                </Badge>
              </Common.Box>
            </Common.Flex>
          </InnerWrapper>
        </Common.Box>
      )}
    </Wrapper>
  )
}

Topbar.propTypes = {
  authenticated: PropTypes.bool,
  balanceCents: PropTypes.number,
  countryCode: PropTypes.string,
  hasNewNotifications: PropTypes.bool,
  isEmbedded: PropTypes.bool,
  location: PropTypes.object.isRequired,
  notificationsVisible: PropTypes.bool,
  returningPlayer: PropTypes.bool.isRequired,
  onShowDeposit: PropTypes.func.isRequired,
  onShowSidebar: PropTypes.func.isRequired,
  onToggleNotifications: PropTypes.func.isRequired,
  showNewMenuItemIcon: PropTypes.bool,
}
