import * as ReactRouter from 'react-router'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { Box, Flex, Space } from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import * as configuration from '../../configuration'
import * as CombinedSelectors from '../../combined-selectors'
import { HtmlContent } from '../common/html-content'
import { LanguageSwitch } from '../language-switch'
import { TobiqueSeal } from '../../tobique-seal'
import { getTranslationKey } from '../../utils/brand-translation-prefix'

import { ImageLinkItem } from './image-link-item'
import { InfoLink } from './info-link'
import { PaymentProviderImages } from './payment-provider-images'

const Wrapper = styled.footer`
  margin: auto;
  padding-top: ${t.spacing(3)};
  width: 90%;
  border-top: solid 1px ${t.color('black2')};
`

const FlexWrapper = styled(Flex)`
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const InfoLinkList = styled.ul`
  order: 2;
  width: 28%;
  font-size: ${t.fontSize(0)};

  @media only screen and (max-width: 600px) {
    order: 1;
    width: 100%;
    margin-top: 0;
  }
`
const LegalText = styled.div`
  margin-bottom: 35px;
  @media only screen and (max-width: 600px) {
    order: 2;
    width: 100%;
    margin-right: 0;
  }
`

export function Footer(props) {
  const i18n = new useI18n()
  const location = ReactRouter.useLocation()
  const isMobile = ReactRedux.useSelector(state =>
    CombinedSelectors.getIsMobile(state)
  )
  const brand = ReactRedux.useSelector(state =>
    configuration.getBrand(state.configuration)
  )

  // Hides the footer when playing games as it messes up the scrolling behaviour
  if (
    location.pathname.match('starsplaypromo') ||
    (location.pathname.match(/casino\/games\/[0-9]+/) && isMobile)
  ) {
    return null
  }

  return (
    <Wrapper>
      <PaymentProviderImages padding={0} />
      <Space pb={3}>
        <LanguageSwitch />
      </Space>
      <FlexWrapper>
        <InfoLinkList>
          <InfoLink
            link="/terms-conditions-bonus"
            translationKey="side-bar.terms-conditions-bonus"
          />
          <InfoLink
            translationKey="footer.links.terms"
            link="/terms-conditions"
          />
          <InfoLink
            link="/terms-conditions-sport"
            translationKey="side-bar.terms-conditions-sport"
          />
          <InfoLink
            translationKey="footer.links.about-us-text"
            link="/about-us"
          />
          {/* <InfoLink
            translationKey="footer.links.affiliates"
            link="https://heropartners.io/"
            target="_blank"
          /> */}
          <InfoLink
            translationKey="footer.links.cookie-policy"
            link="/cookie-policy"
          />
          <InfoLink translationKey="footer.links.help" link="/faq" />
          <InfoLink
            translationKey="footer.links.privacy-policy"
            link="/privacy-policy"
          />
          <InfoLink
            translationKey="footer.links.responsible-gaming"
            link="/responsible-gaming"
          />
          <InfoLink translationKey="footer.links.security" link="/security" />
          <li>
            <Space pt={0}>
              <Flex wrap>
                {brand === 'simplecasino24' ? (
                  <Space paddingRight={1} paddingBottom={1}>
                    <TobiqueSeal />
                  </Space>
                ) : (
                  <ImageLinkItem
                    src="/images/gcb.avif"
                    alt="GCB license"
                    target="_blank"
                    rel="noopener noreferrer"
                    to="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJak55WTJReFUwOXZWRXAwTlhCdldVZG5kMmwzYmxFOVBTSXNJblpoYkhWbElqb2lhbk5rWTNKUVdqQlRPVTU0T1VKdFVuVXdUbkI1UVhWd1NWUTVibFIwZVhZeFRVbDRaVU53WjBKVVZUMGlMQ0p0WVdNaU9pSmhNMkl6TURobE1EVXlOalpqT1dWbVpERmpZV0l3TkdKbE5tVm1OMkZtTVdRMllUTm1OamN6WmpkaFpUaG1ORE0yTTJFd1pqbGlNREl3WXpNd1lqZGhJaXdpZEdGbklqb2lJbjA9"
                  />
                )}
                <ImageLinkItem
                  src={i18n.translate('gamecare-logo')}
                  alt="Gambling Therapy"
                  target="_blank"
                  to="https://www.gamblingtherapy.org/en"
                />
                <ImageLinkItem
                  src={i18n.translate('18-plus')}
                  alt="18+"
                  to="/responsible-gaming"
                />
                <ImageLinkItem
                  src={i18n.translate('casinohallen-approved')}
                  alt="Casinohallen approved"
                  to="https://casinohallen.se/"
                  target="_blank"
                  rel="noopener"
                />
              </Flex>
            </Space>
          </li>
        </InfoLinkList>

        <Box maxWidth="72%" mr="35px">
          <Space pb={3}>
            <LegalText>
              <HtmlContent
                html={{
                  __html: i18n.translate(
                    getTranslationKey(
                      brand,
                      `footer.${props.licenseId}.about-text`
                    )
                  ),
                }}
              />
            </LegalText>
          </Space>
        </Box>
      </FlexWrapper>
    </Wrapper>
  )
}

Footer.propTypes = {
  licenseId: PropTypes.string.isRequired,
}
